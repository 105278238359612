import React, { useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { routes as authRoutes } from '../../../auth/routes';

const INACTIVITY_TIMEOUT_MINUTES =
  import.meta.env.MODE === 'production' ? 15 : 5000;

export const AutoLogout: React.FC = () => {
  const { logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const resetTimeout = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        logout();
        history.push(authRoutes.sessionTimeout.route.create({}));
      }, INACTIVITY_TIMEOUT_MINUTES * 60 * 1000);
    };

    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimeout();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [logout, history]);

  return null;
};
